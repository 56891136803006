(function ($) {


    $('.container-input input').on('focusin',function(){
    $(this).parent().removeClass('error');
    $(this).parent().addClass('active');
});

    $('.container-input input').on('focusout', function(){
        if($(this).val()){
            $(this).parent().addClass('success');
            $(this).parent().removeClass('active');
        }else{
            $(this).parent().removeClass('success');
            $(this).parent().removeClass('active');
        }
    });
    $('form').on('submit',function(e){
        e.preventDefault();
        var i=0;
        $(this).find('.required').each(function(){
            if(!$(this).find('input').val()){
                $(this).addClass('error');
                i++;
            }
        });
        if(i>0){
            return;
        }

        var form = new FormData(this);
        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            data: form,
            processData: false,
            contentType: false,
            success: function() {
                $.fancybox.close();
                $.fancybox.open($('#modal-success'));
                console.log('успех');
            } ,
            error:function(){
                $.fancybox.close();
                $.fancybox.open($('#modal-error'));
                console.log('ошибка');
            }
        });
        $('#modal-success button, #modal-error button').on('click',function(){
            $.fancybox.close();
        });

    });


    //
    $('.container-team-slider .slides').slick({
        autoPlay: true,
        dots: false,
        arrows:true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1359,
                settings: {
                    slidesToShow: 3,
                }
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
        prevArrow: '.btn-team-prev',
        nextArrow: '.btn-team-next'
    });
    $('.actions .container-actions-slider .slides').slick({
        autoPlay: true,
        dots: false,
        arrows:true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1359,
                settings: {
                    slidesToShow: 1,
                }
            }

        ],
        prevArrow: '.btn-actions-prev',
        nextArrow: '.btn-actions-next'
    });
    $('.container-brands-slider .slides').slick({
        autoPlay: true,
        dots: false,
        arrows:true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1359,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                }
            },

        ],
        prevArrow: '.btn-brands-prev',
        nextArrow: '.btn-brands-next'
    });
    $('.main-block .container-main-slider .slides').slick({
        autoPlay: true,
        dots: false,
        arrows:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '.btn-main-prev',
        nextArrow: '.btn-main-next'
    });
    $('.container-examples-slider .slides').slick({
        autoPlay: true,
        dots: false,
        arrows:true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1359,
                settings: {
                    slidesToShow: 3,
                }
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
        prevArrow: '.btn-examples-prev',
        nextArrow: '.btn-examples-next'
    });


    $(".accordion .accordion-body .block").on("click",function(e){
        e.stopPropagation();
        if($(this).hasClass('active')){
            $(this).removeClass('active');
            $(this).find('.block-body').css('height',"0");
        }else{
            $(this).parents(".accordion-body").find('.block.active').removeClass('active').find('.block-body').css('height',"0");
            $(this).addClass('active');
            var heightTable = $(this).find("table").outerHeight(true);
            console.log(heightTable)
            $(this).find('.block-body').css('height', heightTable);
        }
    });

    $.fancybox.defaults.modal = true;
    $('.modal-link').fancybox();
    $('.btn-close').on('click', function(){
        $.fancybox.close();
    });

    $('.menu-link').on('click',function(){
        $('.menu-container').addClass('active');
        $('body').addClass('menu-active');
    });

    $(document).mouseup(function (e){ // событие клика по веб-документу
        var menu = $(".menu-container.active nav"); // тут указываем ID элемента
        if (!menu.is(e.target) // если клик был не по нашему блоку
            && menu.has(e.target).length === 0) { // и не по его дочерним элементам\
            menu.parents('.menu-container.active').removeClass('active'); // скрываем его
            menu.parents('body').removeClass('menu-active'); // скрываем его
        }
    });
    $('.menu-container .parent').on('click', function(e){
        e.stopPropagation();
        $(this).toggleClass('active');
        $(this).find('.parent.active').each(function(){
            $(this).removeClass('active');
        });
    })
    $('.menu-container.active .btn-close').on('click',function(e){
        e.stopPropagation();
        $(this).parent().removeClass('active');

    });
}(jQuery));